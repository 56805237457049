import { ServiceRequest } from "Models";
import { useSelector } from "react-redux";
import { getSimulatorBySimId } from "modules/simulator/simulatorSelectors";
import { DateTime } from "luxon";
import { RootState } from "StoreTypes";
import { motion } from "framer-motion";
import { ServiceRequestType, MotionVariantType } from "types/enums";
import { useDismissServiceRequest } from "modules/service-requests/serviceRequestHooks";
import { containerChildMotionVariants } from "utils/animations/animationHelper";
import { titleCase } from "../../../utils/enums/enumHelper";
import { IoMdAlert } from "react-icons/io";

type ServiceRequestProps = {
  serviceRequest: ServiceRequest;
};

const ServiceRequestContainer: React.FC<ServiceRequestProps> = (props) => {
  const { serviceRequest } = props;
  const serviceRequestType = serviceRequest?.type || 2;

  const simulator = useSelector((state: RootState) =>
    getSimulatorBySimId(state, { simId: serviceRequest.simulatorId })
  );

  const [, dismissServiceRequest] = useDismissServiceRequest(serviceRequest.id);

  return (
    <motion.div
      className="w-full shadow rounded-lg bg-white  dark:bg-zinc-800  dark:text-gray-200 mb-4 p-4 notification-box flex cursor-pointer text-xl"
      variants={containerChildMotionVariants}
      initial={MotionVariantType.REST}
      whileHover={MotionVariantType.HOVER}
      whileTap={MotionVariantType.PRESSED}
      whileDrag={MotionVariantType.EXIT}
      drag="x"
      dragConstraints={{ left: 0 }}
      onTap={dismissServiceRequest}
      onDragEnd={dismissServiceRequest}
      key={serviceRequest.id}
    >
      <IoMdAlert className="size-6 text-red-500 mt-1 mr-1" />
      <div className="flex flex-col col-span-8 w-full">
        <div className="flex flex-row justify-between items-end">
          <h2 className="brand-heading text-md text-gray-900 dark:text-gray-200">
            {simulator?.name}
          </h2>
          <small className="text-sm text-gray-700 dark:text-gray-200">
            {DateTime.fromISO(serviceRequest?.createdDate)?.toRelative()}
          </small>
        </div>
        <div>
          <span className="text-gray-400 text-sm">
            {titleCase(
              ServiceRequestType[serviceRequestType]?.replace("CC_", "")
            )}
          </span>
        </div>
      </div>
    </motion.div>
  );
};
export default ServiceRequestContainer;
